<template>
  <div>
    <b-modal
      id="modal-input-pangan"
      size="lg"
      centered
      title="Tambah Data Master Pangan"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Pangan <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('kode_pangan')"
            type="text"
            v-model="$v.is_data.kode_pangan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Pangan <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_pangan')"
            type="text"
            v-model="$v.is_data.nama_pangan.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kelompok <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_kelompok_pangan_id"
            v-model="$v.is_data.ms_kelompok_pangan_id.$model"
            :options="listKelompokPangan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_kelompok_pangan"
            track-by="ms_kelompok_pangan_id"
            placeholder="-- Pilih kelompok --"
            size="sm"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tipe <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_tipe_pangan_id"
            v-model="$v.is_data.ms_tipe_pangan_id.$model"
            :options="listTipePangan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_tipe_pangan"
            track-by="ms_tipe_pangan_id"
            placeholder="-- Pilih Tipe --"
            size="sm"
          ></Multiselect>
        </b-form-group>
      </div>
      <hr>
      <div class="mt-2">
        <h5 class="mb-3"><strong>KANDUNGAN</strong></h5>
        <b-row class="m-0 p-0">
          <b-col cols="4" v-for="(item, key) of is_data.kandungan_pangan" :key="key">
            <label :for="key+'123'">{{item.nama}}</label>
            <!-- <b-form-input
              :id="key+'123'"
              type="number"
              v-model="is_data.kandungan_pangan[key].berat"
            ></b-form-input> -->
            <b-input-group class="w-100">
              <b-form-input
                :id="key+'123'"
                type="number"
                v-model="is_data.kandungan_pangan[key].berat"
              ></b-form-input>
              <b-input-group-append>
                <b-button>{{is_data.kandungan_pangan[key].satuan}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-pangan')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props:[
    'listTipePangan',
    'listKelompokPangan',
  ],
  data() {
    return {
      is_data: {
        kode_pangan: '',
        nama_pangan: '',
        ms_kelompok_pangan_id: '',
        ms_tipe_pangan_id: '',
        kandungan_pangan: [
          {
            nama: 'Air',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Energi',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Protein',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Lemak',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Karbohidrat',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Serat',
            berat: 0,
            satuan: 'mcg',  
          },
          {
            nama: 'Abu',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Kalsium',
            berat: 0,
            satuan: 'kal',  
          },
          {
            nama: 'Fosfor',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Besi',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Natrium',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Kalium',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Tembaga',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Seng',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Retinol',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Beta Karoten',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Karoten Total',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Thiamin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Riboflavin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Niasin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Vitamin C',
            berat: 0,
            satuan: 'mg', 
          },
        ],
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      kode_pangan: { required },
      nama_pangan: { required },
      kandungan_pangan: {  },
      ms_kelompok_pangan_id: { required },
      ms_tipe_pangan_id: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/ms_pangan/register", {
          ...vm.is_data,
          ms_kelompok_pangan_id: vm.is_data.ms_kelompok_pangan_id.ms_kelompok_pangan_id,
          ms_tipe_pangan_id: vm.is_data.ms_tipe_pangan_id.ms_tipe_pangan_id,
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER PANGAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input-pangan");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        kode_pangan: '',
        nama_pangan: '',
        ms_kelompok_pangan_id: '',
        ms_tipe_pangan_id: '',
        kandungan_pangan: [
          {
            nama: 'Air',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Energi',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Protein',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Lemak',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Karbohidrat',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Serat',
            berat: 0,
            satuan: 'mcg',  
          },
          {
            nama: 'Abu',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Kalsium',
            berat: 0,
            satuan: 'kal',  
          },
          {
            nama: 'Fosfor',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Besi',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Natrium',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Kalium',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Tembaga',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Seng',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Retinol',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Beta Karoten',
            berat: 0,
            satuan: 'gr', 
          },
          {
            nama: 'Karoten Total',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Thiamin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Riboflavin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Niasin',
            berat: 0,
            satuan: 'mg', 
          },
          {
            nama: 'Vitamin C',
            berat: 0,
            satuan: 'mg', 
          },
        ],
      };
      this.$v.$reset();
    },
  },
};
</script>
